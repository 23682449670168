import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { TitlePortal } from 'components/TitlePortal';
import { Link } from 'react-router-dom';
import { useI18nObject } from 'utils/use-i18n-object';
import {
  useDownloadReceipt,
  useRetrieveTransaction,
} from 'api/queries/queries';
import { DataLine, DataLineItem } from 'ui/organisms/summary';
import { formatAmount } from 'utils/format-amount';
import { useStore } from 'effector-react';
import { $user } from 'stores/user';
import { TransactionStatus } from 'models';
import { Icon } from 'ui/atoms/icon';
import { LinkButton } from 'ui/atoms/link-button';
import { $locale } from 'stores/locale';
import moment from 'moment/moment';

export interface Props {}

export const TransactionDetails: React.VFC<Props> = () => {
  const LL = useI18nObject();
  const locale = useStore($locale);
  const user = useStore($user);

  const { transactionId } = useParams<{ transactionId: string }>();

  const { data: transaction } = useRetrieveTransaction(transactionId);

  moment.locale(locale);

  const transactionDate =
    transaction && moment(transaction.created).format('hh:mm A  MMMM Do, YYYY');

  const onDownloadClick = useDownloadReceipt(transaction?.id || 0);

  // const statusStyles = useMemo(() => {
  //   const { status } = transaction;
  //   return {
  //     btn: styles[`${status}Btn`],
  //     underLine: styles[`${status}UnderLine`],
  //   };
  // }, [transaction?.status]);

  if (!transaction) {
    return null;
  }

  // const statusRepr = getPaymentRequestStatusMapping(paymentRequest.status);

  const STATUS_LABELS: { [key in TransactionStatus]: string } = {
    pending: LL.TRANSACTION_PENDING_STATUS(),
    succeeded: LL.TRANSACTION_SUCCEEDED_STATUS(),
    failed: LL.TRANSACTION_FAILED_STATUS(),
  };

  return (
    <>
      <TitlePortal>
        <Link to="/dashboard/transactions">Transactions</Link> / {transactionId}
      </TitlePortal>
      <Content>
        <PaymentConfirmationStyled>
          <HeaderWrapper status={transaction.status}>
            <Header>
              <div>AFiTN {transaction.id}</div>
              <div>{transactionDate}</div>
            </Header>
          </HeaderWrapper>
          <PaymentSummaryStyled>
            <DataLine>
              <DataLineItem isTitle>
                {transaction?.status ? LL.STATUS() : ''}
              </DataLineItem>
              <DataLineItem>
                {transaction?.status ? STATUS_LABELS[transaction?.status] : ''}
              </DataLineItem>
            </DataLine>
            <DataLine>
              <DataLineItem isTitle>{LL.FROM()}</DataLineItem>
              <span>
                {user?.first_name} {user?.last_name}
              </span>
              <DataLineItem secondary separateLine alignRight>
                {transaction.sender_account?.account_title} {LL.ENDING_WITH()}
                {transaction.sender_account?.last_four_digits}
              </DataLineItem>
            </DataLine>
            <DataLine>
              <DataLineItem isTitle>{LL.TO()}</DataLineItem>
              <DataLineItem>
                {transaction.recipient_account?.recipient?.first_name}{' '}
                {transaction.recipient_account?.recipient?.last_name}
              </DataLineItem>
              <DataLineItem secondary separateLine alignRight>
                {transaction.recipient_account?.account_title}{' '}
                {LL.ENDING_WITH()}
                {transaction.recipient_account?.last_four_digits}
              </DataLineItem>
            </DataLine>
            <DataLine>
              <DataLineItem isTitle>{LL.PURPOSE_OF_PAYMENT()}</DataLineItem>
              <DataLineItem>{transaction.category?.name}</DataLineItem>
              <DataLineItem secondary separateLine alignRight>
                {transaction.description}
              </DataLineItem>
            </DataLine>
            <DataLine>
              <DataLineItem isTitle>{LL.AMOUNT_SENT()}</DataLineItem>
              <DataLineItem>
                {formatAmount({
                  amount: transaction.send_amount,
                  currency: transaction.currency,
                })}
              </DataLineItem>
            </DataLine>
            <DataLine>
              <DataLineItem isTitle>{LL.FEES()}</DataLineItem>
              <DataLineItem>
                {formatAmount({
                  amount: transaction.fee,
                  currency: transaction.currency,
                })}
              </DataLineItem>
            </DataLine>
            {transaction.user_paid_elevy && (
              <DataLine>
                <DataLineItem>{LL.TRANSACTION_ELEVY_FEES()}</DataLineItem>
                <DataLineItem>
                  {formatAmount({
                    amount: transaction.gh_elevy_amount,
                    currency: transaction.currency,
                  })}
                </DataLineItem>
              </DataLine>
            )}
            <DataLine>
              <DataLineItem isTitle>
                {LL.AMOUNT_PAID_FEES_INCLUDED()}
              </DataLineItem>
              <DataLineItem>
                {formatAmount({
                  amount: transaction.total_amount,
                  currency: transaction.currency,
                })}
              </DataLineItem>
            </DataLine>
            <DataLine>
              <DataLineItem isTitle>{LL.AMOUNT_RECEIVED()}</DataLineItem>
              <DataLineItem>
                {formatAmount({
                  amount: transaction.recipient_amount,
                  currency: transaction.recipient_currency,
                })}
              </DataLineItem>
            </DataLine>
            {transaction.status === 'succeeded' && (
              <DataLine>
                <DataLineItem isTitle />
                <DataLineItem>
                  <LinkButton onClick={onDownloadClick}>
                    <Icon name="Download" size={20} />
                    <span>{LL.DOWNLOAD_RECEIPT()}</span>
                  </LinkButton>
                </DataLineItem>
              </DataLine>
            )}
          </PaymentSummaryStyled>
        </PaymentConfirmationStyled>
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 0.5rem;

  gap: 16px;
  margin: 8px;
  padding: 0;
`;

const PaymentSummaryStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;

  max-width: 560px;
  margin: 0 auto;
  width: 100%;
`;

const PaymentConfirmationStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div<{ status?: TransactionStatus }>`
  color: white;
  background: white;
  background: ${(p) => p.status === 'succeeded' && 'var(--success-gradient)'};
  background: ${(p) => p.status === 'failed' && 'var(--failed-gradient)'};
  background: ${(p) => p.status === 'pending' && 'var(--pending-gradient)'};
  border-bottom: 1px solid var(--text-color-success-v2);
  border-radius: 0.5rem 0.5rem 0 0;
`;

const Header = styled.header`
  max-width: 560px;
  margin: 0 auto;

  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;
