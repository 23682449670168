import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import useDeferred from 'use-deferred';

import SiteFooter from 'components/SiteFooter/SiteFooter';
import { FinancialAccountPicker } from 'components/FinancialAccountPicker';
import type { RouteComponentProps } from 'react-router';
import { usePayPaymentRequest, useRetrievePaymentRequest } from 'api/queries';
import { useForm } from 'utils/use-form';
import type { AccountTypeI, Operators } from 'models';
import { formatDueDate } from 'utils';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { IonInput, IonItem } from '@ionic/react';
import { Icon } from 'ui/atoms/icon';
import {
  DataLine,
  DataLineHighlight,
  DataLineItem,
  DataMultiline,
} from 'ui/organisms/summary';
import { PoweredBy } from 'ui/atoms/powered-by';
import { AfiLogo } from 'ui/atoms/afi-logo';
import { NotificationPopup } from 'components/NotificationPopup';
import { useI18nObject } from 'utils/use-i18n-object';

import { CreditCardForm } from 'components/vgs/CreditCardForm/CreditCardForm';
import { Input } from 'ui/atoms/input';
import { formatAmount } from 'utils/format-amount';
import { StatusHelper } from 'utils/status-helper';
import { useValidatePayPaymentRequest } from 'api/queries/queries';
import { CurrencyInput } from 'ui/molecules/currency-input';
import { getExchangeRateRepresentation } from 'data/currencies';
import { Button } from 'ui/atoms/button';
import Notifications from '../../components/Notifications';
import { MTNWarning } from '../Transfer/MTNWarning';

interface TParams {
  id: string;
}

interface PayForm {
  accountType: AccountTypeI | '';
  country: string;
  customerName: string;
  mobileNumber: string;
  mobileNumberCountry: string;
  mobileOperator: Operators | '';
  cvv: string;
  bankCode: string;
  bankName: string;
  bankAccountNumber: string;
}

const defaultPayFormValues: PayForm = {
  accountType: '',
  country: '',
  customerName: '',
  mobileNumber: '',
  mobileNumberCountry: '',
  mobileOperator: '',
  cvv: '',
  bankCode: '',
  bankName: '',
  bankAccountNumber: '',
};

export const Pay = ({ match }: RouteComponentProps<TParams>) => {
  const LL = useI18nObject();

  const history = useHistory();
  const { id } = match.params;
  const { data: paymentRequest, isLoading } = useRetrievePaymentRequest(id);

  const creditCardFormRef = useRef<any>();
  const [isCreditFormValid, setIsCreditFormValid] = useState<boolean>(false);

  const [stageId, setStageId] = useState<0 | 1>(0);

  const {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    execute: showMTNWarning,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    resolve: resolveMTNWarning,
    isPending: isMTNWarningPending,
  } = useDeferred<void>();

  const resultUrl = `/result/${id}`;

  const {
    amount,
    currency: requesterCurrency,
    status,
    requester_name,
    title,
    logo,
    due_date: dueDate,
    description,
  } = paymentRequest || {};
  const category = '';
  const requesterName = title || requester_name;

  const requestDoesNotExist =
    (!isLoading && !paymentRequest) || status === 'draft';

  const payMutation = usePayPaymentRequest();

  const formik = useForm<PayForm>({
    initialValues: defaultPayFormValues,
    validate: ({
      accountType,
      cvv,
      mobileNumber,
      mobileOperator,
      customerName,
    }) => {
      const errors: { [key: string]: string } = {};
      if (!accountType) {
        errors.accountType = 'Required';
      } else if (accountType === 'bank_card') {
        if (!isCreditFormValid || !cvv) {
          errors.creditForm = 'Invalid';
        }
      } else if (accountType === 'mobile_money') {
        if (!mobileNumber || mobileNumber.length < 5) {
          errors.mobileNumber = 'Required';
        }
        if (!customerName) {
          errors.customerName = 'Required';
        }
        if (!mobileOperator) {
          errors.mobileOperator = 'Required';
        }
      }
      return errors;
    },
    onSubmit: async (values) => {
      // if (data.account_type === 'bank_card') {
      //   formik.setValues({
      //     ...formik.values,
      //     accountType: 'bank_card',
      //     country: data.country,
      //   });
      // } else if (data.account_type === 'mobile_money') {
      //   formik.setValues({
      //     ...formik.values,
      //     accountType: 'mobile_money',
      //     country: data.country,
      //     customerName: data.customer_name,
      //     mobileNumber: data?.mobile_money?.mobile_number,
      //     mobileNumberCountry: data?.mobile_money?.mobile_numberCountry,
      //     operator: data?.mobile_money?.operator,
      //   });
      // }
      console.log({ accountData });

      const submitData = {
        bank_card: {
          cvv: values.cvv,
        },
        confirmed_details: true,
        payer_account_data: payerAccountData,
        // ...(recipientId && {recipient: recipientId}),
      };

      if (values.accountType === 'bank_card') {
        const response = await creditCardFormRef.current.submit({
          endpoint: `/api/payment_requests/${id}/pay`,
          body: submitData,
        });
        console.log({ response });
        history.push(resultUrl);
      } else {
        const response = await payMutation.mutateAsync({
          id,
          payer_account_data: payerAccountData,
        });
        console.log({ response });

        const { accountType, mobileOperator } = values;
        if (accountType === 'mobile_money') {
          if (mobileOperator === 'MTN') {
            await showMTNWarning();
          } else {
            await Notifications.info(
              {
                message: LL.PLEASE_ENSURE(),
                big: true,
              },
              [{ text: 'OK', value: true }],
            );
          }
        }

        history.push(resultUrl);
      }
    },
  });

  const payerAccountData =
    stageId === 1 && formik.isValid && formik.values.accountType
      ? {
          country: formik.values.country,
          customer_name: formik.values.customerName,
          account_type: formik.values.accountType,
          ...(formik.values.accountType === 'mobile_money' && {
            mobile_money: {
              operator: formik.values.mobileOperator,
              mobile_numberCountry: formik.values.mobileNumberCountry,
              mobile_number: formik.values.mobileNumber,
            },
          }),
          confirmed_details: true,
        }
      : undefined;

  const onSubmitAttempt = (e?: React.SyntheticEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (stageId === 0) {
      setStageId(1);
    } else if (stageId === 1) {
      formik.onSubmitAttempt();
    }
  };

  const { data: validationResult, isSuccess } = useValidatePayPaymentRequest({
    id,
    ...(payerAccountData && {
      payerAccountData: {
        ...payerAccountData,
        ...(formik.values.accountType === 'bank_card' && {
          bank_card: {
            brand: 'visa',
            card_number: '_',
            expiration_month: '_',
            expiration_year: '_',
            last_four_digits: '_',
          },
        }),
        customer_name: payerAccountData?.customer_name || '_',
      },
    }),
  });

  const minLimit = Number(validationResult?.min_limit);
  const maxLimit = Number(validationResult?.max_limit);
  const recipientMinLimit = Number(validationResult?.recipient_min_limit);
  const recipientMaxLimit = Number(validationResult?.recipient_max_limit);

  const fee = validationResult?.fee;
  const totalAmount = validationResult?.total_amount;
  const exchangeRate = Number(validationResult?.exchange_rate) || 1;
  const sendAmount = validationResult?.send_amount;
  const recipientAmount = validationResult?.recipient_amount;
  const recipientCurrency = validationResult?.recipient_currency;
  const currency = validationResult?.currency;
  const customer_pays_elevy = validationResult?.customer_pays_elevy;
  const gh_elevy_amount = validationResult?.gh_elevy_amount;

  const isCrossborderTransfer = currency !== recipientCurrency;

  const { accountType, country, customerName, cvv } = formik.values;

  const accountData = {
    accountType: formik.values.accountType,
    country: formik.values.country,
    bankCode: formik.values.bankCode,
    bankName: formik.values.bankName,
    bankAccountNumber: formik.values.bankAccountNumber,
    mobileOperator: formik.values.mobileOperator,
    mobileNumber: formik.values.mobileNumber,
  };

  const onAccountDataChange = useCallback(
    (data: ComponentProps<typeof FinancialAccountPicker>['accountData']) => {
      formik.setValues({
        ...formik.values,
        ...data,
      });
    },
    [formik.values, formik.setFieldValue],
  );

  const isBankAccountFormVisible =
    country !== 'US' && accountType === 'bank_account';
  const isMobileMoneyFormVisible =
    country !== 'US' && accountType === 'mobile_money';
  const isCreditCardFormVisible = accountType === 'bank_card';

  return (
    <Container>
      <StatusHelperStyled />
      {isMTNWarningPending && (
        <NotificationPopup
          onClose={() => resolveMTNWarning()}
          iconComponent={
            <Icon
              name="AlertOctogram"
              size={72}
              color="var(--primary-color-main)"
            />
          }
        >
          <MTNWarning />
        </NotificationPopup>
      )}
      <Link to="/">
        {logo ? (
          <LogoContainer alt={requesterName} title={requesterName} src={logo} />
        ) : (
          <AfiLogo />
        )}
      </Link>
      <Content>
        {status === 'paid' && <Redirect to={resultUrl} />}
        {requestDoesNotExist && (
          <Header>{LL.THIS_REQUEST_DOES_NOT_EXIST()}</Header>
        )}
        {status === 'cancelled' && (
          <Header>{LL.THIS_REQUEST_IS_REVOKED()}</Header>
        )}
        {status === 'expired' && (
          <Header>{LL.THIS_REQUEST_IS_EXPIRED()}</Header>
        )}
        {status === 'processing_payment' && <Redirect to={resultUrl} />}
        {status === 'issued' && (
          <Header>
            {LL.REQUESTER_REQUESTED_AMOUNT({
              requesterName,
              currencyAmount: formatAmount({
                currency: requesterCurrency,
                amount,
              }),
            })}
          </Header>
        )}
        {status === 'issued' && (
          <FormContainer>
            <RequesterInformation>
              <DataLineHighlight>
                <span>{requesterName}</span>
                <span />
              </DataLineHighlight>
              <DataLine>
                <span>{LL.REQUEST_ID()}</span>
                <span>{id}</span>
              </DataLine>
              {category && (
                <DataMultiline>
                  <span>{LL.CATEGORY()}</span>
                  <span>{category}</span>
                </DataMultiline>
              )}
              <DataMultiline>
                <span>{LL.DESCRIPTION()}</span>
                <span>{description}</span>
              </DataMultiline>
              <DataLine>
                <span>{LL.AMOUNT_REQUESTED()}</span>
                <span>
                  {formatAmount({ currency: requesterCurrency, amount })}
                </span>
              </DataLine>
              <DataLine>
                <span>{LL.DUE_DATE()}</span>
                <span>{dueDate && formatDueDate(dueDate)}</span>
              </DataLine>
            </RequesterInformation>
            <PaymentForm onSubmit={onSubmitAttempt}>
              <SliderContainer>
                <Stage hidden={stageId !== 0}>
                  <StageContent>
                    <FinancialAccountPicker
                      directionType="pull"
                      accountData={accountData}
                      onAccountDataChange={onAccountDataChange}
                    />
                    {(isBankAccountFormVisible || isMobileMoneyFormVisible) && (
                      <AccountHolderNameInput
                        value={customerName}
                        onInputChange={(value) => {
                          formik.setFieldValue('customerName', value);
                        }}
                        name="customerName"
                        placeholder={LL.ACCOUNT_HOLDER_NAME()}
                        // required
                      />
                    )}
                    <CreditCardFormWrapper hidden={!isCreditCardFormVisible}>
                      <CreditCardForm
                        ref={creditCardFormRef}
                        onValid={setIsCreditFormValid}
                      />
                    </CreditCardFormWrapper>
                    {accountType === 'bank_card' && (
                      <CVVInput className="input-field">
                        <IonItem className="input-item" lines="none">
                          <IonInput
                            maxlength={3}
                            onInput={formik.handleChange('cvv')}
                            required
                            className="input"
                            name="cvv"
                            value={cvv}
                            placeholder="CVV"
                          />
                        </IonItem>
                      </CVVInput>
                    )}
                    <Button disabled={!formik.isValid}>{LL.NEXT()}</Button>
                  </StageContent>
                </Stage>
                <Stage hidden={stageId !== 1}>
                  <StageContent>
                    <PaymentDetails>
                      <CurrencyInput
                        title={LL.YOU_SEND()}
                        name="amount"
                        defaultValue={Number(sendAmount)}
                        currency={currency}
                        min={minLimit}
                        max={maxLimit}
                        readOnly
                      />
                      <TransactionNumbers>
                        {isCrossborderTransfer && (
                          <DataLine>
                            <DataLineItem>{LL.CONVERSION_RATE()}</DataLineItem>
                            <DataLineItem>
                              {!!(currency && recipientCurrency) &&
                                getExchangeRateRepresentation({
                                  currency,
                                  recipientCurrency,
                                  exchangeRate,
                                })}
                            </DataLineItem>
                          </DataLine>
                        )}
                        <DataLine>
                          <DataLineItem>{LL.TRANSACTION_FEES()}</DataLineItem>
                          <DataLineItem>
                            {formatAmount({
                              amount: fee,
                              currency,
                            })}
                          </DataLineItem>
                        </DataLine>
                        {customer_pays_elevy && (
                          <DataLine>
                            <DataLineItem>
                              {LL.TRANSACTION_ELEVY_FEES()}
                            </DataLineItem>
                            <DataLineItem>
                              {formatAmount({
                                amount: gh_elevy_amount,
                                currency,
                              })}
                            </DataLineItem>
                          </DataLine>
                        )}
                        <DataLineHighlight>
                          <DataLineItem>{LL.TOTAL_PAID()}</DataLineItem>
                          <DataLineItem>
                            {formatAmount({ amount: totalAmount, currency })}
                          </DataLineItem>
                        </DataLineHighlight>
                      </TransactionNumbers>
                      <CurrencyInput
                        title={LL.RECIPIENT_GETS()}
                        name="amount"
                        defaultValue={Number(recipientAmount)}
                        currency={recipientCurrency}
                        min={recipientMinLimit}
                        max={recipientMaxLimit}
                        readOnly
                      />
                    </PaymentDetails>
                    <Button
                      type="button"
                      ghost
                      onClick={() => {
                        setStageId(0);
                      }}
                    >
                      {LL.BACK()}
                    </Button>
                    <Button
                      disabled={
                        !formik.isValid || formik.isSubmitting || !isSuccess
                      }
                    >
                      {LL.PAY()}
                    </Button>
                  </StageContent>
                </Stage>
              </SliderContainer>
            </PaymentForm>
          </FormContainer>
        )}
      </Content>
      {!!logo && <PoweredBy />}
      <SiteFooter />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  padding: 16px;
`;

const LogoContainer = styled.img`
  max-width: 320px;
`;

const Content = styled.div`
  width: 100%;
  border: none;
  background-color: #ffffff;
  max-width: 700px;
  margin: auto;
  box-sizing: content-box;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h1`
  font-weight: 400;
  margin-bottom: 40px;
  padding: 0 24px;
`;

const FormContainer = styled.div``;

const PaymentForm = styled.form``;

const RequesterInformation = styled.div`
  padding: 24px;
  min-width: 280px;
`;

const CVVInput = styled.div`
  margin-top: 16px;
`;

const AccountHolderNameInput = styled(Input)`
  margin-bottom: 16px;
  margin-top: -16px;
`;
const CreditCardFormWrapper = styled.div`
  margin-top: -16px;
`;

const StatusHelperStyled = styled(StatusHelper)`
  max-width: 700px;
  border-radius: 0.5rem;
`;

const TransactionNumbers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
`;

const SliderContainer = styled.div`
  flex: 1;

  background: white;
  border-radius: 0;
`;

const Stage = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StageContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 0;
  min-height: calc(100% - 70px);
  align-items: center;

  > * {
    width: 100%;
  }
`;
