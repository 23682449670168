import type {
  AccountTypeI,
  Capability,
  CapabilityPaymentMethod,
  CapabilityPaymentMethodBank,
  CountryCapability,
  DirectionI,
  FinancialAccount,
  OperatorOption,
} from 'models';
import { i18nObject } from 'i18n/i18n-util';
import { $locale } from 'stores/locale';
import PrefundAccountIcon from 'assets/icon/logo-black-and-white.svg';
import BankCardIcon from '../assets/icon/bank-card.svg';
import BankAccountIcon from '../assets/icon/bank-account.svg';
import MobileMoneyIcon from '../assets/icon/mobile-money.svg';

export enum TransactionStatusId {
  initial = 'initial',
  pending = 'pending',
  succeeded = 'succeeded',
  failed = 'failed',
}

export const TransactionStatusName = {
  [TransactionStatusId.initial]: 'Pending',
  [TransactionStatusId.pending]: 'Pending',
  [TransactionStatusId.succeeded]: 'Succeeded',
  [TransactionStatusId.failed]: 'Failed',
};

export const AccountTypeIcon: { [key in AccountTypeI]: string } = {
  bank_card: BankCardIcon,
  bank_account: BankAccountIcon,
  mobile_money: MobileMoneyIcon,
  prefund_account: PrefundAccountIcon,
};

interface Account {
  id: AccountTypeI;
  name: string;
  icon: string;
}

export const AccountTypeList: Account[] = [
  { id: 'bank_card', name: 'Debit / credit card', icon: '' },
  { id: 'bank_account', name: 'Bank account', icon: '' },
  { id: 'mobile_money', name: 'Mobile money', icon: '' },
];

export enum VerificationTypes {
  email = 'email',
  phoneNumber = 'phone_number',
  accountDeletionEmail = 'request_account_deletion',
}

export enum VerificationActions {
  changeEmail = 'change_email',
  changePhoneNumber = 'change_phone_number',
}

// TODO call the SenderMobileMoneyOperators and RecipientMobileMoneyOperators from API
const GHMobileMoneyOperators: OperatorOption[] = [
  { id: 'MTN', name: 'MTN' },
  { id: 'AIRTEL', name: 'AirtelTigo' },
  { id: 'VODAFONE', name: 'Telecel' },
];

const TGMobileMoneyOperators: OperatorOption[] = [
  { id: 'FLOOZ', name: 'FLOOZ' },
  { id: 'TMONEY', name: 'Mixx by Yas' },
];

const TGMobileMoneyPullOperators: OperatorOption[] = [
  { id: 'FLOOZ', name: 'FLOOZ' },
  { id: 'TMONEY', name: 'Mixx by Yas' },
];

const TGMobileMoneyPushOperators: OperatorOption[] = [
  { id: 'FLOOZ', name: 'FLOOZ' },
  { id: 'TMONEY', name: 'Mixx by Yas' },
];

export const getOperatorsList = ({
  direction,
  country,
}: {
  direction: 'pull' | 'push' | 'both';
  country: string;
}) => {
  if (country === 'GH') {
    return GHMobileMoneyOperators;
  }
  if (country === 'TG') {
    if (direction === 'pull') {
      return TGMobileMoneyPullOperators;
    }
    if (direction === 'push') {
      return TGMobileMoneyPushOperators;
    }
    return TGMobileMoneyOperators;
  }
  return [];
};

export const getCountryRealCapabilities = ({
  selectedCountry,
  capabilities,
}: {
  selectedCountry: string;
  capabilities: CountryCapability[];
}): Capability[] => {
  const allCountries = capabilities.find(({ country }) => country === null);
  let allCountryCapabilitiesObj = Object.fromEntries(
    (allCountries?.capabilities || []).map((capability) => [
      capability.payment_flow,
      capability,
    ]),
  );

  const country = capabilities.find(
    ({ country }) => country === selectedCountry,
  );
  if (country) {
    const { capabilities } = country;
    const realCapabilities = capabilities.map((capability) => {
      const { [capability.payment_flow]: allCountryPaymentFlow, ...rest } =
        allCountryCapabilitiesObj;
      allCountryCapabilitiesObj = rest;
      return {
        ...capability,
        payment_methods: [
          ...capability.payment_methods,
          ...(allCountryPaymentFlow?.payment_methods || []),
        ],
      };
    });
    return [...realCapabilities, ...Object.values(allCountryCapabilitiesObj)];
  }
  if (allCountries) {
    return allCountries.capabilities;
  }
  return [];
};

export const getAvailableAccountTypes = ({
  direction,
  selectedCountry,
  countryCapabilities,
}: {
  direction: DirectionI | 'both';
  selectedCountry: string;
  countryCapabilities: CountryCapability[];
}) => {
  const capabilities = getCountryRealCapabilities({
    selectedCountry,
    capabilities: countryCapabilities,
  });

  const availableMethods = Array<AccountTypeI>().concat(
    ...capabilities
      .filter(
        ({ payment_flow }) =>
          direction === 'both' || payment_flow === direction,
      )
      .map((flow) => {
        return flow.payment_methods.map(({ type }) => type);
      }),
  );

  return AccountTypeList.filter(({ id }) => availableMethods.includes(id));
};

export const getAvailableAccounts = ({
  direction,
  financialAccounts,
  capabilities,
}: {
  direction: DirectionI;
  financialAccounts: FinancialAccount[];
  capabilities: CountryCapability[];
}) =>
  financialAccounts
    ?.map((f) => {
      let available = true;
      if (f.restrict) {
        available = false;
      } else {
        const availableAccounts = getAvailableAccountTypes({
          direction,
          selectedCountry: f.country || '',
          countryCapabilities: capabilities || [],
        });
        available = availableAccounts
          .map(({ id }) => id)
          .includes(f.account_type);
        if (f.account_type === 'mobile_money' && f.mobile_money?.operator) {
          const availableOperators = getOperatorsList({
            direction,
            country: f.country || '',
          });
          available = availableOperators
            .map(({ id }) => id)
            .includes(f.mobile_money?.operator);
        }
      }
      return { ...f, available };
    })
    .sort((a, b) => (a.available ? -1 : 1));

export const getAvailableBanks = ({
  selectedCountry,
  direction,
  countryCapabilities,
}: {
  selectedCountry: string;
  direction: DirectionI | 'both';
  countryCapabilities: CountryCapability[];
}) => {
  const capabilities = getCountryRealCapabilities({
    selectedCountry,
    capabilities: countryCapabilities,
  });

  const bankAccountMethods = Array<CapabilityPaymentMethod>().concat(
    ...capabilities
      .filter(
        ({ payment_flow }) =>
          direction === 'both' || payment_flow === direction,
      )
      .map((flow) => {
        return flow.payment_methods.filter(
          ({ type }) => type === 'bank_account',
        );
      }),
  );

  const banks = Array<CapabilityPaymentMethodBank>().concat(
    ...bankAccountMethods.map(({ banks }) => banks),
  );

  const uniqueBanks = banks.filter(
    (value, index, self) =>
      self.findIndex(({ bank_code }) => bank_code === value.bank_code) ===
      index,
  );

  uniqueBanks.sort((a, b) => {
    const nameA = a.bank_name.toUpperCase();
    const nameB = b.bank_name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return uniqueBanks;
};

export const getAccountTypeNameById = (id: AccountTypeI) => {
  const LL = i18nObject($locale.getState());

  switch (id) {
    case 'bank_account':
      return LL.BANK_ACCOUNT();
    case 'mobile_money':
      return LL.MOBILE_MONEY();
    case 'bank_card':
    default:
      return LL.BANK_CARD();
  }
};

export const WEB_LINKS = {
  facebook: 'https://www.facebook.com/AFiPayments/',
  twitter: 'https://twitter.com/AFiPayments',
  linkedin: '',
  instagram: 'https://www.instagram.com/afipayments/',
};

export const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
];
